import ContentBlock from '../../ContentBlock/ContentBlock';
import styles from './Footer.module.css';

const Footer = (props: any) => (
  <div className={styles.footer}>
    <ContentBlock show={props.show}>
      <p>
        &copy; 2024 UNSW Sydney "Nail It!" &middot; CRICOS Provider Code 00098G
      </p>
      <p>
        <a href="https://www.unsw.edu.au/privacy" target="blank">
          Privacy Policy
        </a>
        ,{' '}
        <a
          href="https://www.futurestudents.unsw.edu.au/unsw-gateway"
          target="blank">
          About Us
        </a>
        <style jsx global>{`
          a {
            color: black;
          }
        `}</style>
      </p>
    </ContentBlock>
  </div>
);

export default Footer;

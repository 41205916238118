import Header from './Header/Header';
import Footer from './Footer/Footer';
import styles from './Layout.module.css';
import useMedia from 'use-media';

export default function Layout(props: any) {
  const isNarrow = useMedia({ maxWidth: '900px' });

  const layoutStyle: any = {};
  if (props.backgroundSize) {
    layoutStyle.backgroundSize = props.show
      ? `auto ${isNarrow ? props.backgroundMobileSize : props.backgroundSize}`
      : `auto 1000%`;
  }
  if (props.backgroundColor) {
    layoutStyle.backgroundColor = props.show ? props.backgroundColor : 'white';
  }
  if (props.backgroundShape) {
    layoutStyle.backgroundImage = `url(${props.backgroundShape})`;
  }
  layoutStyle.backgroundPosition = props.show
    ? props.overrideBackgroundPosition || (isNarrow ? '50% 20%' : '-100px 30%')
    : '50% 50%';
  return (
    <div className={styles.Layout} style={layoutStyle}>
      <Header show={props.show} />
      {props.children}
      <Footer show={props.show} />
    </div>
  );
}

import { NextPage } from 'next';
import Layout from '../components/Layout/Layout';
import { useEffect, useState } from 'react';
import ContentBlock from '../components/ContentBlock/ContentBlock';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import useLocalStorageState from 'use-local-storage-state';
import ButtonArrow from '../components/ButtonArrow/ButtonArrow';

const Home: NextPage = () => {
  const { errors, register, handleSubmit } = useForm();
  const [showPage, setShowPage] = useState(false);
  const [username, setUsername] = useLocalStorageState('username');
  const router = useRouter();

  useEffect(() => {
    setShowPage(true);
  }, []);

  const onSubmit = async (data: any) => {
    if (username !== '') {
      setShowPage(false);
      router.push(`/userEmail`);
    }
  };

  return (
    <Layout
      backgroundColor={'#ffdc00'}
      backgroundShape={require('../assets/shape-1.svg')}
      backgroundSize={'160%'}
      backgroundMobileSize={'160%'}
      overrideBackgroundPosition={'-100px 60%'}
      show={showPage}>
      <ContentBlock show={showPage}>
        <h1>
          Your
          <br />
          <u>Gateway to UNSW</u>
        </h1>
        <h4>Prepare your application for an early offer at UNSW</h4>
        <p>You’re going to learn how to create a personal statement.</p>
        <h2>What shall we call you?</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex inputField">
            <input
              className={`input ${
                errors && errors['name'] ? 'input-error' : ''
              }`}
              id="name"
              type="text"
              aria-label="Your name"
              aria-required={true}
              name="name"
              placeholder="Your name..."
              ref={register({ required: true })}
              onChange={(e: any) => setUsername(e.target.value)}
              value={username}
            />
          </div>
          <div className="navButtons">
            <ButtonArrow label="Next" textColor="black" activeColor="#ffdc00" />
          </div>
        </form>
      </ContentBlock>
    </Layout>
  );
};

export default Home;

import Logo from '../../../assets/nail-it-logo.svg';
import ContentBlock from '../../ContentBlock/ContentBlock';
import styles from './Header.module.css';

const Header = (props: any) => (
  <ContentBlock show={props.show}>
    <img className={styles.Logo} src={Logo} alt="Nail It!" />
  </ContentBlock>
);

export default Header;

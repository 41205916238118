import styles from './ContentBlock.module.css';

export default function ContentBlock(props: any) {
  return (
    <div
      className={styles.contentBlock}
      style={{ opacity: props.show ? 1 : 0 }}>
      {props.children}
    </div>
  );
}

import { ButtonArrowProps } from '../../lib/types';
import { useState } from 'react';
import React from 'react';

export const arrowIcon = (
  color: string,
  iconRotation: number,
  position = 'absolute'
) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.88 4.12L13.76 12L5.88 19.88L8 22L18 12L8 2L5.88 4.12Z"
        fill={color}
      />
      <style jsx>{`
        svg {
          transform: rotate(${iconRotation}deg);
          position: ${position};
          left: 11px;
        }
      `}</style>
    </svg>
  );
};

const ButtonArrow = React.forwardRef(
  (
    {
      activeColor,
      textColor,
      label,
      iconRotation,
      onClick,
      floatRight,
      submitType = true,
    }: ButtonArrowProps,
    ref
  ) => {
    const [isHovering, setIsHovering] = useState(false);
    const [isMouseDown, setIsMouseDown] = useState(false);

    return (
      <button
        type={submitType ? 'submit' : 'button'}
        onClick={onClick}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => {
          setIsHovering(false);
          setIsMouseDown(false);
        }}
        onMouseDown={() => setIsMouseDown(true)}
        onMouseUp={() => setIsMouseDown(false)}>
        {arrowIcon(
          isHovering ? (isMouseDown ? activeColor : 'white') : textColor,
          iconRotation || 0
        )}
        {label}
        <style jsx>{`
          button {
            ${floatRight ? `position: absolute; right: 0px;` : ``}
            background-color: ${isHovering ? 'black' : activeColor};
            color: ${isHovering
              ? isMouseDown
                ? activeColor
                : 'white'
              : textColor};
          }
        `}</style>
      </button>
    );
  }
);

export default ButtonArrow;
